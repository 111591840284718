<template>
  <div>
    <vs-card>
      <h4
        class="mb-0 ml-2 text-uppercase"
        :style="{ color: colorSecondary }"
      >
        Selecione o projeto desejado
      </h4>

      <hr class="custom-hr">

      <!-- Survey Grouped By Types -->
      <vs-collapse accordion>
        <div
          v-for="surveyGroup in surveysGroupedByType"
          :key="surveyGroup.surveyType"
        >
          <vs-collapse-item icon-arrow="add">
            <div
              slot="header"
              class="pb-2 text-uppercase font-weight-bold border-bottom"
            >
              {{ getTypeDescription(surveyGroup.surveyType) }}
            </div>

            <vs-table
              v-model="selected"
              class="pl-3"
              :data="surveyGroup.values"
              @selected="goToSurveyResult"
            >
              <template slot="thead">
                <vs-th>Projeto</vs-th>
                <vs-th>Total</vs-th>
                <vs-th>Concluído</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  v-for="survey in data"
                  :key="survey.surveyID"
                  class="border-top custom-tr"
                  :data="survey"
                >
                  <!-- Title -->
                  <vs-td>
                    <div class="d-flex align-items-center">
                      <img
                        :src="getProjectLogo(survey.projectID)"
                        width="45"
                      >

                      <div class="ml-4">
                        <div class="d-flex align-items-center mb-1">
                          <h5 class="mr-2 font-16 font-medium">
                            {{ survey.surveyName }}
                          </h5>

                          <vs-chip
                            v-if="checkItemAccess('Admin')"
                            class="custom-chip"
                          >
                            {{ survey.surveyID }}
                          </vs-chip>
                        </div>

                        <span>{{ survey.entityName }}</span>
                      </div>
                    </div>
                  </vs-td>

                  <!-- Total -->
                  <vs-td>
                    {{ survey.qtTotal }}
                  </vs-td>

                  <!-- Status -->
                  <vs-td>
                    {{ survey.concludedTot }} /
                    <b>{{ survey.concludedPerc }}%</b>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-collapse-item>
        </div>
      </vs-collapse>
    </vs-card>
  </div>
</template>

<script>
/* Config */
import { configDomain } from '@/global';

/* Services */
import Survey from '@/services/survey';

const surveyService = new Survey();

export default {
  name: 'SurveyListByType',
  props: {
    id: {
      type: String,
      default: 'all',
    },
  },
  data: () => ({
    surveys: [],
    surveyTypes: [],
    selected: {},
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    /* Table */
    // Filter surveys based on page id
    filteredSurveys() {
      if (this.surveys.length === 0) return [];

      let filteredSurveys = [...this.surveys];

      filteredSurveys = filteredSurveys.filter(({ surveyType }) => {
        if (this.id === 'surveys') {
          return surveyType === '1';
        } else if (this.id === 'projects') {
          return surveyType !== '5' && surveyType !== '1';
        } else if (this.id === 'default') {
          return surveyType !== '5' && surveyType !== '1' && surveyType !== '8';
        } else {
          return surveyType !== '5';
        }
      });

      return filteredSurveys;
    },
    surveysGroupedByType() {
      const groupedSurveys = this.filteredSurveys.reduce((result, survey) => {
        const existingEntry = result.find((entry) => entry.surveyType === survey.surveyType);

        if (existingEntry) {
          existingEntry.values.push(survey);
        } else {
          result.push({
            surveyType: survey.surveyType,
            values: [survey],
          });
        }

        return result;
      }, []);

      return groupedSurveys;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  created() {
    this.getSurveys();
    this.getSurveyTypes();
  },
  methods: {
    /* API */
    getSurveys() {
      this.$store.dispatch('set_isLoadingActive', true);
      surveyService.getSurveysOwner(this.ownerId, this.token)
        .then((res) => (this.surveys = res.surveys))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getProjectLogo(projectId) {
      return `${configDomain}/prj/${projectId}/logo.png`;
    },
    getSurveyTypes() {
      surveyService.getSurveyTypes(this.token)
        .then((res) => (this.surveyTypes = res));
    },
    /* Helpers */
    checkItemAccess(access) {
      if (!access) return true;
      return this.$store.state.loginData.accessValues.includes(access);
    },
    getTypeDescription(type) {
      return this.surveyTypes
        .find((t) => String(t.typeId) === String(type)).typeDesc;
    },
    /* Router */
    goToSurveyResult(survey) {
      this.$store.dispatch('set_isLoadingActive', true);

      this.$router.push({
        name: 'SurveyResultPage',
        params: {
          surveyId: survey.surveyID,
          surveyName: survey.surveyName,
          projectId: survey.projectID,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-tr {
  cursor: pointer;

  &:hover {
    background-color: #EEE;
  }
}
</style>

<style>
.custom-chip.con-vs-chip {
  color: #323232 !important;
  min-height: 20px !important;
}
</style>
